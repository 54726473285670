import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from 'firebase/firestore';
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  UploadTaskSnapshot,
} from 'firebase/storage';
import { KnowledgeCentreSort } from '../utils/constants/knowledgeCentre.contants';
import { KnowledgeItem } from '../utils/types/knowledgeCentre';
import { db, storage } from './firebase';

export interface KnowledgeCentreFilterType {
  keyword?: string;
}

export const createFirebase = async (
  item: KnowledgeItem
): Promise<KnowledgeItem> => {
  try {
    const docRef = await addDoc(collection(db, 'knowledge-items'), item);
    return { id: docRef.id, ...item };
  } catch (error) {
    console.error('Error creating knowledge item:', error);
    throw error;
  }
};

export const updateFirebase = async (
  item: KnowledgeItem
): Promise<KnowledgeItem> => {
  if (!item.id) throw new Error('No id provided');

  try {
    const itemRef = doc(db, 'knowledge-items', item.id);
    await updateDoc(itemRef, { ...item, user: '' });
    return item;
  } catch (error) {
    console.error('Error updating knowledge item:', error);
    throw error;
  }
};

export const deleteFirebase = async (
  item: KnowledgeItem
): Promise<KnowledgeItem> => {
  if (!item.id) throw new Error('No id provided');

  try {
    const itemRef = doc(db, 'knowledge-items', item.id);
    await deleteDoc(itemRef);
    return item;
  } catch (error) {
    console.error('Error deleting knowledge item:', error);
    throw error;
  }
};

const transformKnowledgeCentre = (
  doc: DocumentSnapshot<DocumentData>
): KnowledgeItem => {
  const data = doc.data();
  if (!data) {
    throw new Error('Document data is undefined');
  }

  return {
    id: doc.id,
    title: data.title || '',
    image: data.image || '',
    extract: data.extract || '',
    rawText: data.rawText || '',
    text: data.text || '',
    user: data.user || 'Unknown User',
    published: new Date(data.published?.seconds * 1000 || Date.now()),
    last_updated: new Date(
      data.last_updated?.seconds * 1000 || data.published?.seconds * 1000
    ),
  };
};

export const getBatchFirebase = async (
  lastDoc: KnowledgeItem | null,
  sort: KnowledgeCentreSort
): Promise<Array<KnowledgeItem>> => {
  let knowledgeItems;

  const [field, _sort] = sort.split('-');
  const sortType = _sort === 'desc' ? 'desc' : 'asc';

  const knowledgeItemsCollection = collection(db, 'knowledge-items');

  try {
    const baseQuery = query(
      knowledgeItemsCollection,
      orderBy(field, sortType),
      limit(20)
    );

    if (lastDoc && lastDoc.id) {
      const lastDocRef = doc(knowledgeItemsCollection, lastDoc.id);
      const paginatedQuery = query(
        knowledgeItemsCollection,
        orderBy(field, sortType),
        startAfter(lastDocRef),
        limit(20)
      );

      knowledgeItems = await getDocs(paginatedQuery);
    } else {
      knowledgeItems = await getDocs(baseQuery);
    }
  } catch (error) {
    console.error('Error fetching knowledge centre batch:', error);
    throw error;
  }

  return knowledgeItems.docs.map((doc) => ({
    id: doc.id,
    title: doc.data().title || '',
    image: doc.data().image || '',
    extract: doc.data().extract || '',
    rawText: doc.data().rawText || '',
    text: doc.data().text || '',
    user: doc.data().user || 'Unknown User',
    published: new Date(doc.data().published?.seconds * 1000 || Date.now()),
    last_updated: new Date(
      doc.data().last_updated?.seconds * 1000 ||
        doc.data().published?.seconds * 1000
    ),
  }));
};

export const getFullKnowledgeCentre = async (
  sort: KnowledgeCentreSort
): Promise<Array<KnowledgeItem>> => {
  const [field, _sort] = sort.split('-');
  const sortType = _sort === 'desc' ? 'desc' : 'asc';

  try {
    const knowledgeCentreCollection = collection(db, 'knowledge-items');
    const knowledgeCentreQuery = query(
      knowledgeCentreCollection,
      orderBy(field, sortType)
    );
    const knowledgeCentre = await getDocs(knowledgeCentreQuery);

    return knowledgeCentre.docs.map((doc: DocumentSnapshot) =>
      transformKnowledgeCentre(doc)
    );
  } catch (error) {
    console.error('Error fetching full knowledCentre:', error);
    throw error;
  }
};

export const uploadImageToFirebase = async (file: File): Promise<string> => {
  const storageRef = ref(
    storage,
    `knowledge-items/${Date.now()}_${file.name.replace(/\s+/g, '_')}`
  );
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise<string>((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (_snapshot: UploadTaskSnapshot) => {
        // TODO: at some point show progress
        // let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)) * 100
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        } catch (error) {
          reject(error);
        }
      }
    );
  });
};
