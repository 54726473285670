import {
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { useContext, useEffect, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Descendant } from 'slate';
import { KnowledgeCentreStore } from '../../providers/knowledgeCentre';
import FileUploader from '../common/FileUploader';
import StyledInput from '../common/StyledInput';
import TextEditor from '../common/TextEditor';

export interface KnowledgeCentreFormFields {
  id?: string;
  title: string;
  extract: string;
  text: Descendant[];
  publishDate: Date;
  publishTime: Date;
  imageInfo?: {
    storageUrl: string;
  };
}
interface KnowledgeCentreFormSectionProps {
  control: Control<KnowledgeCentreFormFields>;
  errors: any;
  handleUpload: any;
  supportedFileFormats: string[];
  lastUpdatedDate?: Date;
}

const useStyles = makeStyles((theme) => ({
  input: {
    'label + &': {
      marginTop: 0,
      color: theme.palette.text.secondary,
    },
  },
  textField: {
    width: '100%',
  },
  errorMessage: {
    height: theme.spacing(6),
  },
}));

const KnowledgeCentreFormSection = ({
  control,
  errors,
  handleUpload,
  supportedFileFormats,
  lastUpdatedDate,
}: KnowledgeCentreFormSectionProps): JSX.Element => {
  const classes = useStyles();
  const textErrorRef = useRef<HTMLInputElement>(null);
  const {
    state: { contentImageUrl, uploading },
    uploadContentImage,
  } = useContext(KnowledgeCentreStore);

  useEffect(() => {
    if (errors.text && !errors.title) {
      textErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors.text]);

  return (
    <>
      <Box>
        <StyledInput
          name="title"
          label="Title"
          control={control}
          error={!!errors.title}
          classnames={{ formControl: classes.input }}
        />
        {errors.title && (
          <Typography
            variant="body1"
            color="error"
            className={classes.errorMessage}
            align="left"
          >
            {errors.title.message}
          </Typography>
        )}
      </Box>
      <Box mt={10}>
        <FileUploader
          title={'Image'}
          filepathPlaceholder={
            control.defaultValuesRef.current?.imageInfo?.storageUrl
          }
          onUpload={handleUpload}
          disabled={uploading}
          supportedFileFormats={supportedFileFormats}
        />
        {errors.imageInfo && (
          <Typography
            variant="body1"
            color="error"
            className={classes.errorMessage}
            align="left"
          >
            {errors.imageInfo.message}
          </Typography>
        )}
        <Box mt={5}>{uploading && <CircularProgress />}</Box>
      </Box>
      <Box mt={{ xs: 4, sm: 4, md: 4, lg: 17 }}>
        <Typography variant="subtitle1" align="left">
          Extract (max 345 characters)
        </Typography>
        <Controller
          control={control}
          name="extract"
          render={({ field: { onChange, value } }) => (
            <TextField
              multiline={true}
              rows={5}
              classes={{ root: classes.textField }}
              value={value}
              onChange={onChange}
            ></TextField>
          )}
        />
        {errors.extract && (
          <Typography
            variant="body1"
            color="error"
            className={classes.errorMessage}
            align="left"
          >
            {errors.extract.message}
          </Typography>
        )}
        <Box mt={10} mb={10}>
          <Controller
            control={control}
            name="text"
            render={({ field: { onChange, value } }) => {
              return control?.defaultValuesRef?.current?.title ||
                !control?.defaultValuesRef?.current?.id?.length ? (
                <TextEditor
                  title="Body"
                  value={value}
                  onChange={onChange}
                  placeholder="Add the main knowledge article text..."
                  contentImageUrl={contentImageUrl}
                  uploadContentImage={uploadContentImage}
                />
              ) : (
                <></>
              );
            }}
          />
          {errors.text && (
            <Typography
              variant="body1"
              color="error"
              className={classes.errorMessage}
              align="left"
              ref={textErrorRef}
            >
              {errors.text.message}
            </Typography>
          )}
        </Box>
        <Box mt={10} mb={10}>
          <Typography variant="body1" align="left">
            Last updated:{' '}
            {lastUpdatedDate &&
              format(lastUpdatedDate, "dd MMM yyyy hh:mm aaaaa'm'")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default KnowledgeCentreFormSection;
