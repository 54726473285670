import { createContext, useCallback, useReducer, useState } from 'react';
import {
  initialTooltipsState,
  TOOLTIPS_ACTIONS,
  TooltipsReducer,
  TooltipsState,
} from '../reducers/tooltips';
import { uploadImageToFirebase } from '../services/common';
import {
  createFirebase,
  deleteFirebase,
  getBatchFirebase,
  getFullTooltips,
  TooltipsFilterType,
  updateFirebase,
} from '../services/tooltips';
import { TooltipsSort } from '../utils/constants/tooltipsSort.constants';
import { Tooltip } from '../utils/types/tooltip';

interface TooltipsContext {
  state: TooltipsState;
  create: (tooltip: Tooltip) => void;
  update: (tooltip: Tooltip) => void;
  remove: (tooltip: Tooltip) => void;
  uploadContentImage: (_file: File) => void;
  getTooltipsBatch: (lastDoc: Tooltip | null) => void;
  onKeywordChange: (value: string) => void;
  onSortChange: (value: TooltipsSort) => void;
  search: () => void;
  filter: TooltipsFilterType;
  sort: TooltipsSort;
  filteredTooltips: Tooltip[] | null;
  fullDataFetched: boolean;
}

export const TooltipsStore = createContext<TooltipsContext>({
  state: initialTooltipsState,
  create: () => {},
  update: () => {},
  remove: () => {},
  uploadContentImage: () => {},
  getTooltipsBatch: async () => {},
  onKeywordChange: () => {},
  onSortChange: () => {},
  search: () => {},
  filter: {},
  filteredTooltips: null,
  sort: 'updated-desc',
  fullDataFetched: false,
});

const { Provider } = TooltipsStore;
export const TooltipsProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(TooltipsReducer, initialTooltipsState);
  const [filter, setFilter] = useState({ keyword: '' });
  const [sort, setSort] = useState<TooltipsSort>('updated-desc');
  const [filteredTooltips, setfilteredTooltips] = useState<Tooltip[] | null>(
    null
  );
  const [fullDataFetched, setFullDataFetched] = useState(false);

  const create = useCallback(
    async (tooltip: Tooltip) => {
      dispatch({ type: TOOLTIPS_ACTIONS.UPDATE_START });
      const _createdTooltip = await createFirebase({
        ...tooltip,
      });
      dispatch({
        type: TOOLTIPS_ACTIONS.UPDATE_SUCCESS,
        data: [...(state.data || []), _createdTooltip],
      });
    },
    [state]
  );

  const update = useCallback(
    async (tooltip: Tooltip) => {
      dispatch({ type: TOOLTIPS_ACTIONS.UPDATE_START });
      await updateFirebase({ ...tooltip });
      let editedTooltipIndex = state.data?.findIndex((t: Tooltip) => {
        return t.id === tooltip.id;
      });
      let newData = state.data ? [...state.data] : undefined;
      if (
        newData &&
        editedTooltipIndex !== undefined &&
        editedTooltipIndex > -1
      ) {
        newData[editedTooltipIndex] = tooltip;
      }
      dispatch({ type: TOOLTIPS_ACTIONS.UPDATE_SUCCESS, data: newData });
    },
    [state]
  );

  const remove = useCallback(
    async (tooltip: Tooltip) => {
      dispatch({ type: TOOLTIPS_ACTIONS.REMOVE_START });
      await deleteFirebase(tooltip);
      let index = state.data?.findIndex((t: Tooltip) => {
        return t.id === tooltip.id;
      });
      if (index !== undefined && index > -1) {
        let data = state.data;
        data?.splice(index, 1);
        dispatch({ type: TOOLTIPS_ACTIONS.REMOVE_SUCCESS, data });
      }
    },
    [state]
  );

  const getInitialTooltipsBatch = useCallback(async (sort: TooltipsSort) => {
    dispatch({ type: TOOLTIPS_ACTIONS.FETCH });

    const tooltips = await getBatchFirebase(null, sort);

    dispatch({
      type: TOOLTIPS_ACTIONS.FETCH_INITIAL_DATA_SUCCESS,
      loading: false,
      data: tooltips,
    });
  }, []);

  const getTooltipsBatch = useCallback(async (lastDoc: Tooltip | null) => {
    dispatch({ type: TOOLTIPS_ACTIONS.FETCH });
    const tooltip = await getBatchFirebase(lastDoc, sort);

    dispatch({
      type: TOOLTIPS_ACTIONS.FETCH_DATA_SUCCESS,
      loading: false,
      data: tooltip,
    });
  }, []);

  const uploadContentImage = useCallback(async (file: File) => {
    dispatch({ type: TOOLTIPS_ACTIONS.IMAGE_UPLOAD_START });
    const _imageUrl = await uploadImageToFirebase(file, 'tooltips');
    dispatch({
      type: TOOLTIPS_ACTIONS.IMAGE_UPLOAD_SUCCESS,
      data: { contentImageUrl: _imageUrl },
    });
  }, []);

  const manuallySortTooltip = useCallback(
    (sortValue: string, data: Tooltip[]) => {
      const [field, _sort] = sortValue.split('-');
      let sortedTooltip;
      if (field === 'updated') {
        sortedTooltip = data?.sort((g1: Tooltip, g2: Tooltip) => {
          return g1.updated > g2.updated ? -1 : 1;
        });
        if (_sort === 'asc') {
          sortedTooltip = data?.sort((g1: Tooltip, g2: Tooltip) => {
            return g1.updated < g2.updated ? -1 : 1;
          });
        }
      }
      if (field === 'title') {
        sortedTooltip = data?.sort((g1: Tooltip, g2: Tooltip) => {
          return g1.title > g2.title ? -1 : 1;
        });
        if (_sort === 'asc') {
          sortedTooltip = data?.sort((g1: Tooltip, g2: Tooltip) => {
            return g1.title < g2.title ? -1 : 1;
          });
        }
      }
      if (filteredTooltips) {
        setfilteredTooltips(sortedTooltip || []);
      } else {
        dispatch({
          type: TOOLTIPS_ACTIONS.FETCH_INITIAL_DATA_SUCCESS,
          data: sortedTooltip,
        });
      }
    },
    [filteredTooltips]
  );

  const onSortChange = useCallback(
    async (value: TooltipsSort) => {
      setSort(value);
      if (fullDataFetched) {
        manuallySortTooltip(value, filteredTooltips || state.data || []);
      } else {
        getInitialTooltipsBatch(value);
      }
    },
    [setSort, fullDataFetched, filteredTooltips, state.data]
  );

  const onKeywordChange = useCallback(
    async (value: string) => {
      setFilter({ keyword: value });
      if (!value) {
        setfilteredTooltips(null);
      }
    },
    [setFilter, filteredTooltips, state.data]
  );

  const search = useCallback(async () => {
    const { keyword } = filter;
    let knowledgeCentre = state.data || [];

    if (!fullDataFetched) {
      dispatch({ type: TOOLTIPS_ACTIONS.FETCH });
      knowledgeCentre = await getFullTooltips();

      dispatch({
        type: TOOLTIPS_ACTIONS.FETCH_INITIAL_DATA_SUCCESS,
        data: knowledgeCentre,
      });
      setFullDataFetched(true);
    }

    const _filteredTooltips = knowledgeCentre.filter((term) =>
      term.title.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
    );

    setfilteredTooltips(_filteredTooltips);
  }, [filter, state]);

  return (
    <Provider
      value={{
        state,
        create,
        update,
        remove,
        uploadContentImage,
        getTooltipsBatch,
        onKeywordChange,
        search,
        filter,
        filteredTooltips,
        onSortChange,
        sort,
        fullDataFetched,
      }}
    >
      {children}
    </Provider>
  );
};
