import { format } from 'date-fns';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { KnowledgeCentreStore } from '../../providers/knowledgeCentre';
import { KNOWLEDGE_CENTRE_EDIT_ROUTE } from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { KnowledgeItem } from '../../utils/types/knowledgeCentre';
import VirtualizedListItem from '../common/VirtualizedListItem';
import RemoveConfirmationDialog from '../manage-tiles/RemoveConfirmationDialog';
import KnowledgeListItemContent from './KnowledgeListItemContent';

interface KnowledgeCentreListProps {
  itemsList: KnowledgeItem[];
  loadMoreItemsHandler: (lastDoc: KnowledgeItem) => void;
}

interface ListRowProps {
  style: any;
  index: number;
  data: KnowledgeItem[];
}

const Row = ({ style, index, data }: ListRowProps) => {
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [knowledgeItem, setKnowledgeItem] = useState<KnowledgeItem | null>(
    null
  );
  const { remove } = useContext(KnowledgeCentreStore);
  let history = useHistory();

  const onConfirmDialogClose = () => {
    setIsRemoveDialogOpened(false);
  };
  const onRemove = () => {
    setIsRemoveDialogOpened(true);
    setKnowledgeItem(data[index]);
  };
  const onEdit = useCallback(
    (id: string) => {
      history.push(KNOWLEDGE_CENTRE_EDIT_ROUTE.replace(':id', id));
    },
    [history]
  );

  return (
    <VirtualizedListItem
      style={{
        ...style,
        width: 'calc(100% - 40px)',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
      }}
    >
      <KnowledgeListItemContent
        date={format(data[index].last_updated, "MMM, dd, yyyy hh:mm aaaaa'm'")}
        title={data[index].title}
        id={data[index].id}
        image={data[index].image}
        onRemove={() => onRemove()}
        onEdit={() => onEdit(data[index].id || '')}
      ></KnowledgeListItemContent>
      {knowledgeItem && (
        <RemoveConfirmationDialog
          isDialogOpened={isRemoveDialogOpened}
          onDialogClose={onConfirmDialogClose}
          dataToRemove={knowledgeItem}
          title="Do you want to remove this article?"
          remove={remove}
        />
      )}
    </VirtualizedListItem>
  );
};

const KnowledgeCentreList: React.FC<KnowledgeCentreListProps> = ({
  itemsList,
  loadMoreItemsHandler,
}): JSX.Element => {
  const listRef = useRef<FixedSizeList>(null);

  const {
    state: { loading },
    filteredKnowledgeCentre,
  } = useContext(KnowledgeCentreStore);
  const itemsCount = itemsList.length;
  const filteredItemsCount = filteredKnowledgeCentre?.length;

  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number) => {
      const shouldReload = itemsCount >= 20 && itemsCount - stopIndex <= 2;

      if (shouldReload && !loading) {
        loadMoreItemsHandler(itemsList[itemsCount - 1]);
      }
    },
    [loading, itemsCount, loadMoreItemsHandler]
  );

  const isItemLoaded = useCallback((_index: number) => {
    //TODO: dynamic loading is not available yet
    // console.log(index);
    return false;
  }, []);

  return (
    <AutoSizer>
      {({ height, width }: any) =>
        filteredKnowledgeCentre ? (
          <FixedSizeList
            height={height}
            itemCount={filteredItemsCount || 0}
            itemSize={LAYOUT.VirtuilizedArticlesListItemHeight}
            width={width}
            itemData={filteredKnowledgeCentre}
            ref={listRef}
          >
            {Row}
          </FixedSizeList>
        ) : (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemsCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <FixedSizeList
                onItemsRendered={onItemsRendered}
                height={height}
                itemCount={itemsCount}
                itemSize={LAYOUT.VirtuilizedArticlesListItemHeight}
                width={width}
                itemData={itemsList}
                ref={listRef}
              >
                {Row}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )
      }
    </AutoSizer>
  );
};

export default KnowledgeCentreList;
