import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { KnowledgeCentreStore } from '../../providers/knowledgeCentre';
import { serialize } from '../../utils/helpers/htmlSerializer.helpers';
import KnowledgeAddEditForm from './KnowledgeAddEditForm';
import { KnowledgeCentreFormFields } from './KnowledgeCentreFormSection';

const KnowledgeCentreAddContent = () => {
  const history = useHistory();

  const { create } = useContext(KnowledgeCentreStore);

  const getPublishDateTime = (date: Date, time: Date): Date => {
    const dateTime = new Date(date);
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
    return dateTime;
  };

  const handleCreate = async (data: KnowledgeCentreFormFields) => {
    const knowledgeItem = {
      title: data.title,
      image: '',
      published: getPublishDateTime(data.publishDate, data.publishTime),
      extract: data.extract,
      rawText: data.text,
      text: serialize({ children: data.text }),
      last_updated: getPublishDateTime(data.publishDate, data.publishTime),
    };
    await create(knowledgeItem);
    history.goBack();
  };

  return (
    <KnowledgeAddEditForm
      handleUpdate={handleCreate}
      title="Add Knowledge Article"
    />
  );
};

export default KnowledgeCentreAddContent;
