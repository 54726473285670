import { createContext, useCallback, useReducer, useState } from 'react';
import {
  initialKnowledgeCentreState,
  KNOWLEDGE_CENTRE_ACTIONS,
  KnowledgeCentreReducer,
  KnowledgeCentreState,
} from '../reducers/knowledgeCentre';
import {
  createFirebase,
  deleteFirebase,
  getBatchFirebase,
  getFullKnowledgeCentre,
  KnowledgeCentreFilterType,
  updateFirebase,
  uploadImageToFirebase,
} from '../services/knowledgeCentre';
import { KnowledgeCentreSort } from '../utils/constants/knowledgeCentre.contants';
import { KnowledgeItem } from '../utils/types/knowledgeCentre';

interface KnowledgeCentreContext {
  state: KnowledgeCentreState;
  create: (item: KnowledgeItem) => void;
  update: (item: KnowledgeItem) => void;
  remove: (item: KnowledgeItem) => void;
  upload: (file: File) => void;
  uploadContentImage: (_file: File) => void;
  getBatch: (lastDoc: KnowledgeItem | null) => void;
  onKeywordChange: (value: string) => void;
  onSortChange: (value: KnowledgeCentreSort) => void;
  search: () => void;
  filter: KnowledgeCentreFilterType;
  sort: KnowledgeCentreSort;
  filteredKnowledgeCentre: KnowledgeItem[] | null;
  fullDataFetched: boolean;
}

export const KnowledgeCentreStore = createContext<KnowledgeCentreContext>({
  state: initialKnowledgeCentreState,
  create: () => {},
  update: () => {},
  remove: () => {},
  upload: () => {},
  uploadContentImage: () => {},
  getBatch: async () => {},
  onKeywordChange: () => {},
  onSortChange: () => {},
  search: () => {},
  filter: {},
  filteredKnowledgeCentre: null,
  sort: 'published-desc',
  fullDataFetched: false,
});

const { Provider } = KnowledgeCentreStore;

export const KnowledgeCentreProvider = ({ children }: any) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [state, dispatch] = useReducer(
    KnowledgeCentreReducer,
    initialKnowledgeCentreState
  );
  const [filter, setFilter] = useState({ keyword: '' });
  const [sort, setSort] = useState<KnowledgeCentreSort>('published-desc');
  const [filteredKnowledgeCentre, setfilteredKnowledgeCentre] = useState<
    KnowledgeItem[] | null
  >(null);
  const [fullDataFetched, setFullDataFetched] = useState(false);

  const create = useCallback(
    async (item: KnowledgeItem) => {
      dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.UPDATE_START });
      const _createdItem = await createFirebase({
        ...item,
        image: imageUrl || '',
      });
      setImageUrl(undefined);
      dispatch({
        type: KNOWLEDGE_CENTRE_ACTIONS.UPDATE_SUCCESS,
        data: [...(state.data || []), _createdItem],
      });
    },
    [imageUrl, state]
  );

  const update = useCallback(
    async (item: KnowledgeItem) => {
      dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.UPDATE_START });
      const result = await updateFirebase({
        ...item,
        image: imageUrl || item.image,
      });
      setImageUrl(undefined);
      let editedItemIndex = state.data?.findIndex((a: KnowledgeItem) => {
        return a.id === item.id;
      });
      let newData = state.data ? [...state.data] : undefined;
      if (newData && editedItemIndex !== undefined && editedItemIndex > -1) {
        newData[editedItemIndex] = result;
      }
      dispatch({
        type: KNOWLEDGE_CENTRE_ACTIONS.UPDATE_SUCCESS,
        data: newData,
      });
    },
    [imageUrl, state]
  );

  const remove = useCallback(
    async (item: KnowledgeItem) => {
      dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.REMOVE_START });
      await deleteFirebase(item);
      let index = state.data?.findIndex((a: KnowledgeItem) => {
        return a.id === item.id;
      });
      if (index !== undefined && index > -1) {
        let data = state.data;
        data?.splice(index, 1);
        dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.REMOVE_SUCCESS, data });
      }
    },
    [state]
  );

  const getInitialKnowledgeCentreBatch = useCallback(
    async (sort: KnowledgeCentreSort) => {
      dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.FETCH });

      const knowledgeItems = await getBatchFirebase(null, sort);

      dispatch({
        type: KNOWLEDGE_CENTRE_ACTIONS.FETCH_INITIAL_DATA_SUCCESS,
        loading: false,
        data: knowledgeItems,
      });
    },
    []
  );

  const getBatch = useCallback(
    async (lastDoc: KnowledgeItem | null) => {
      dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.FETCH });
      const knowledgeItems = await getBatchFirebase(lastDoc, sort);

      dispatch({
        type: KNOWLEDGE_CENTRE_ACTIONS.FETCH_DATA_SUCCESS,
        loading: false,
        data: knowledgeItems,
      });
    },
    [sort]
  );

  const upload = useCallback(async (file: File) => {
    dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.IMAGE_UPLOAD_START });
    const _imageUrl = await uploadImageToFirebase(file);
    setImageUrl(_imageUrl);
    dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.IMAGE_UPLOAD_SUCCESS });
  }, []);

  const uploadContentImage = useCallback(async (file: File) => {
    dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.IMAGE_UPLOAD_START });
    const _imageUrl = await uploadImageToFirebase(file);
    dispatch({
      type: KNOWLEDGE_CENTRE_ACTIONS.IMAGE_UPLOAD_SUCCESS,
      data: { contentImageUrl: _imageUrl },
    });
  }, []);

  const manuallySortKnowledgeItem = useCallback(
    (sortValue: string, data: KnowledgeItem[]) => {
      const [field, _sort] = sortValue.split('-');
      let sortedKnowledgeItem;
      if (field === 'published') {
        sortedKnowledgeItem = data?.sort(
          (g1: KnowledgeItem, g2: KnowledgeItem) => {
            return g1.published > g2.published ? -1 : 1;
          }
        );
        if (_sort === 'asc') {
          sortedKnowledgeItem = data?.sort(
            (g1: KnowledgeItem, g2: KnowledgeItem) => {
              return g1.published < g2.published ? -1 : 1;
            }
          );
        }
      }
      if (field === 'title') {
        sortedKnowledgeItem = data?.sort(
          (g1: KnowledgeItem, g2: KnowledgeItem) => {
            return g1.title > g2.title ? -1 : 1;
          }
        );
        if (_sort === 'asc') {
          sortedKnowledgeItem = data?.sort(
            (g1: KnowledgeItem, g2: KnowledgeItem) => {
              return g1.title < g2.title ? -1 : 1;
            }
          );
        }
      }
      if (filteredKnowledgeCentre) {
        setfilteredKnowledgeCentre(sortedKnowledgeItem || []);
      } else {
        dispatch({
          type: KNOWLEDGE_CENTRE_ACTIONS.FETCH_INITIAL_DATA_SUCCESS,
          data: sortedKnowledgeItem,
        });
      }
    },
    [filteredKnowledgeCentre]
  );

  const onSortChange = useCallback(
    async (value: KnowledgeCentreSort) => {
      setSort(value);
      if (fullDataFetched) {
        manuallySortKnowledgeItem(
          value,
          filteredKnowledgeCentre || state.data || []
        );
      } else {
        getInitialKnowledgeCentreBatch(value);
      }
    },
    [setSort, fullDataFetched, filteredKnowledgeCentre, state.data]
  );

  const onKeywordChange = useCallback(
    async (value: string) => {
      setFilter({ keyword: value });
      if (!value) {
        setfilteredKnowledgeCentre(null);
      }
    },
    [setFilter, filteredKnowledgeCentre, state.data]
  );

  const search = useCallback(async () => {
    const { keyword } = filter;
    let knowledgeCentre = state.data || [];

    if (!fullDataFetched) {
      dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.FETCH });
      knowledgeCentre = await getFullKnowledgeCentre(sort);

      dispatch({
        type: KNOWLEDGE_CENTRE_ACTIONS.FETCH_INITIAL_DATA_SUCCESS,
        data: knowledgeCentre,
      });
      setFullDataFetched(true);
    }

    const _filteredKnowledgeCentre = knowledgeCentre.filter((term) =>
      term.title.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
    );

    setfilteredKnowledgeCentre(_filteredKnowledgeCentre);
  }, [filter, state]);

  return (
    <Provider
      value={{
        state,
        create,
        update,
        remove,
        upload,
        uploadContentImage,
        getBatch,
        onKeywordChange,
        search,
        filter,
        filteredKnowledgeCentre,
        onSortChange,
        sort,
        fullDataFetched,
      }}
    >
      {children}
    </Provider>
  );
};
