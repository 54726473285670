import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Organisation name is required')
    .max(245, 'Must be no longer than 245 characters'),
  address_line_1: yup
    .string()
    .required('Address line 1 is required')
    .max(245, 'Must be no longer than 245 characters'),
  address_line_2: yup
    .string()
    .nullable()
    .max(245, 'Must be no longer than 245 characters'),
  address_line_3: yup
    .string()
    .nullable()
    .max(245, 'Must be no longer than 245 characters'),
  city: yup
    .string()
    .required('City is required')
    .max(100, 'Must be no longer than 100 characters'),
  post_code: yup
    .string()
    .required('Post code is required')
    .max(20, 'Must be no longer than 20 characters'),
  country: yup
    .string()
    .required('Country is required')
    .max(100, 'Must be no longer than 100 characters'),
  region: yup
    .string()
    .required('Region is required')
    .max(100, 'Must be no longer than 100 characters'),
});
