import { User } from './user';

export interface Organisation {
  id: number;
  name: string;
  address_line_1: string;
  address_line_2: string | null;
  address_line_3: string | null;
  city: string;
  post_code: string;
  country: string;
  region: string;
  created_at: Date | string;
  updated_at: Date;
  focus_centre_enabled: boolean;
  type: string;
  max_enabled: boolean;
}

export interface OrganisationListItem {
  id: number;
  name: string;
  created_at: Date | string;
}

export interface OrganisationListItems {
  organisations: OrganisationListItem[];
}

export type OrganisationFormFields = {
  name: string;
  address_line_1: string;
  address_line_2: string | null;
  address_line_3: string | null;
  city: string;
  post_code: string;
  country: string;
  region: string;
  focus_centre_enabled: boolean;
  type: string;
  max_enabled: boolean;
  [key: string]: string | boolean | null;
};

export interface OrganisationAddFormData {
  organization: {
    name: string;
    address_line_1?: string | null;
    address_line_2?: string | null;
    address_line_3?: string | null;
    city: string | null;
    post_code: string;
    region?: string | null;
    focus_centre_enabled: boolean;
    type: string;
    max_enabled: boolean;
  };
}

export enum UserOrganisationRole {
  admin = 'admin',
  standard = 'standard',
  lite = 'lite',
}

export const ORGANISATION_ROLE_OPTIONS = [
  {
    name: 'Admin',
    value: UserOrganisationRole.admin,
  },
  {
    name: 'Pro',
    value: UserOrganisationRole.standard,
  },
  {
    name: 'Go',
    value: UserOrganisationRole.lite,
  },
];

export type UserOrganisationDetails = {
  id: string;
  organization_id: number;
  organization_role: UserOrganisationRole;
  restricted?: boolean;
  created_at: number;
  updated_at: number;
};

export type ExtendedUserDetails = UserOrganisationDetails & User;

export type OrganisationUsers = ExtendedUserDetails[];
