import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useContext } from 'react';
import { GlossaryStore } from '../../providers/glossary';
import Searchbar from '../common/Searchbar';

interface GlossarySearchProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 55,
    minHeight: theme.spacing(10),
    marginLeft: theme.spacing(5),
    borderRadius: 10,
    border: 0,
  },
}));

const GlossarySearch: React.FC<GlossarySearchProps> = (): JSX.Element => {
  const classes = useStyles();
  const {
    search,
    onKeywordChange,
    filter: { keyword },
  } = useContext(GlossaryStore);

  const onSearch = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      search();
    },
    [search]
  );

  const onClear = useCallback(() => {
    onKeywordChange('');
  }, [onKeywordChange]);

  const onChange = useCallback((value: string) => {
    onKeywordChange(value);
  }, []);

  return (
    <form>
      <Box display="flex">
        <Searchbar
          keyword={keyword || ''}
          onChange={onChange}
          onClear={onClear}
        />
        <Button
          classes={{
            root: classes.root,
          }}
          variant="contained"
          color="primary"
          onClick={onSearch}
          type="submit"
        >
          <SearchIcon fontSize="medium" />
        </Button>
      </Box>
    </form>
  );
};

export default GlossarySearch;
